<template>
  <div>
    <div :style="{height: props.height +'px',background: props.bgColor}" class='empty-line'></div>
  </div>
</template>
<script>
export default {
  name: 'add-img',
  props: {
    props: {
      type: Object
    }
  },
  setup (props, { root }) {
    return {

    }
  }
}
</script>
<style lang="less" scoped>
  .empty-line {
    width: 100%;
    background: #fff;
  }
</style>
